import { ErrorBoundary } from '../pages/ErrorBoundary'
import { Dashboard } from '../pages/Dashboard'
import { Routes } from '../Routes'
import { Navigate, RouteObject } from 'react-router-dom'
import { CreateOrganization } from '../pages/CreateOrganization'
import { QuickStart } from '../pages/QuickStart'
import { CreateStore } from '../pages/CreateStore'
import { ProfileSettings } from '../pages/ProfileSettings'
import { SubscriptionSettings } from '../pages/SubscriptionSettings'
import { SupportSettings } from '../pages/SupportSettings'
import { SetupUTM } from '../pages/SetupUTM/SetupUTM'
import { SetupTracking } from '../pages/SetupTracking'
import { useContext } from 'react'
import { JwtContext } from '../contexts/jwtContext'
import { useLocation } from 'react-router-dom'
import { ProgressContainer } from '../components/ProgressContainer'
import { TimezoneProvider } from '../hooks/useDayjsHook'
import { GlobalStateProvider } from '../contexts/GlobalStateContext'

const PrivateRoute = ({ children }: { children: React.ReactNode }) => {
  const decodedToken = useContext(JwtContext)
  const hasOrganiztion = !!decodedToken?.user.organizationId
  const hasStore = (decodedToken?.stores.length || 0) > 0
  const location = useLocation()

  const searchParams = location.search;

  if (location.pathname === Routes.CREATE_ORGANIZATION && hasOrganiztion) {
    return <Navigate to={`${Routes.DASHBOARD}${searchParams}`} />
  }
  if (location.pathname === Routes.CREATE_STORE && hasStore) {
    return <Navigate to={`${Routes.DASHBOARD}${searchParams}`} />
  }
  if (decodedToken && !hasOrganiztion && location.pathname !== Routes.CREATE_ORGANIZATION) {
    return <Navigate to={`${Routes.CREATE_ORGANIZATION}${searchParams}`} />
  }
  if (decodedToken && hasOrganiztion && !hasStore && location.pathname !== Routes.CREATE_STORE) {
    return <Navigate to={`${Routes.CREATE_STORE}${searchParams}`} />
  }
  return (
    <>
      {decodedToken && decodedToken.scope === 'full' ? (
        <GlobalStateProvider>
          <TimezoneProvider>
            {children}
            {location.pathname !== Routes.CREATE_ORGANIZATION && <ProgressContainer />}
          </TimezoneProvider>
        </GlobalStateProvider>
      ) : (
        <Navigate to={`${Routes.LOGIN}${searchParams}`} />
      )}
    </>
  )
}

export const privateRoutes: RouteObject[] = [
  {
    path: Routes.DASHBOARD,
    element: (
      <PrivateRoute>
        <Dashboard />
      </PrivateRoute>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: Routes.CREATE_ORGANIZATION,
    element: (
      <PrivateRoute>
        <CreateOrganization />
      </PrivateRoute>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: `${Routes.QUICK_START}/*`,
    element: (
      <PrivateRoute>
        <QuickStart />
      </PrivateRoute>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: Routes.CREATE_STORE,
    element: (
      <PrivateRoute>
        <CreateStore />
      </PrivateRoute>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: Routes.PROFILE_SETTINGS,
    element: (
      <PrivateRoute>
        <ProfileSettings />
      </PrivateRoute>
    ),
    errorElement: <ErrorBoundary />,
  },
  ...(import.meta.env.VITE_SUBSCRIPTIONS_ENABLED === 'true' ? [{
    path: Routes.SUBSCRIPTION_SETTINGS,
    element: (
      <PrivateRoute>
        <SubscriptionSettings />
      </PrivateRoute>
    ),
    errorElement: <ErrorBoundary />,
  }] : []),
  {
    path: Routes.SUPPORT_SETTINGS,
    element: (
      <PrivateRoute>
        <SupportSettings />
      </PrivateRoute>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: Routes.SETUP_UTM,
    element: (
      <PrivateRoute>
        <SetupUTM />
      </PrivateRoute>
    ),
  },
  {
    path: Routes.SETUP_TRACKING,
    element: (
      <PrivateRoute>
        <SetupTracking />
      </PrivateRoute>
    ),
    errorElement: <ErrorBoundary />,
  },
]
