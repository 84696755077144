import { Row as _Row, Col } from "antd";
import styled from "styled-components";
import { breakpoints } from "../../theme/breakpoints";
import { CreateOrganizationForm } from "../../components/CreateOrganizationForm";
import { Background } from "../../components/StyledComponents";
import fox from "../../assets/snow-fox.jpg"
import { InfoCircleOutlined } from "@ant-design/icons";
import { ShopifyAuth } from "../../components/ShopifyAuth";

const Row = styled(_Row)`
  min-height: 100vh;
  height: fit-content;
`;

const Container = styled.div`
  padding: 16px;
  @media (max-width: ${breakpoints.sm}){
    height: 100%;
    margin: auto;
    padding: 0;
  }
`

export const CreateOrganization = () => {

  return (
    <>
      <ShopifyAuth attemptLink={false} icon={<InfoCircleOutlined />} title="FoxTrax Shopify App" description="To link your Shopify store, you'll need to register or log in." />
      <Row>
        <Col xs={0} sm={0} md={12} lg={12}>
          <Background src={fox} scale="stretch">
          </Background>
        </Col>
        <Col
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          xs={24} sm={24} md={12} lg={12}
        >
          <Container>
            <CreateOrganizationForm />
          </Container>
        </Col>
      </Row>
    </>
  );
};
