import React, { useState } from 'react'
import {
  UserOutlined,
  SettingOutlined,
  RocketOutlined,
  DollarOutlined,
  MailOutlined,
  BarChartOutlined,
} from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { MenuProps, Layout, Menu } from 'antd'
import { Routes } from '../../Routes'
import logo from '../../assets/logo.svg'
import shopify from '../../assets/shopify.svg'
import styled from 'styled-components'
import facebook from '../../assets/fb.png'
import track from '../../assets/paw.png'
import { LocalStorageKeys } from '../../common/types/localStorage'
import { localStorageGetItem, localStorageSetItem } from '../../services/storage'
import { RowKey } from '../../machines/dashboardMachine/dashboardMachine'

const { Sider } = Layout

const StyledImage = styled('img')`
   {
    width: 15px;
    height: 15px;
  }
`

type MenuItem = Required<MenuProps>['items'][number]

function getItem(label: React.ReactNode, key: RowKey, icon?: React.ReactNode, children?: MenuItem[]): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem
}

export function NavigationMenu(primaryPageKey: string) {
  return (
    <Menu
      defaultSelectedKeys={['1']}
      style={{ border: 0 }}
      selectedKeys={[primaryPageKey]}
      mode="inline"
      items={[
        getItem(<Link to={Routes.DASHBOARD}>Dashboard</Link>, 'dashboard', <BarChartOutlined />),
        getItem('Tracking', 'sub1', <StyledImage src={track} />, [
          getItem(<Link to={Routes.SETUP_TRACKING}>Setup Pixel</Link>, 'setup-tracking', <StyledImage src={shopify} />),
          getItem(<Link to={Routes.SETUP_UTM}>Setup UTMs</Link>, 'setup-utm', <StyledImage src={facebook} />),
          getItem(<Link to={Routes.QUICK_START}>Quick Start</Link>, 'quick-start', <RocketOutlined />),
        ]),
        getItem('Settings', 'sub3', <SettingOutlined />, [
          getItem(<Link to={Routes.PROFILE_SETTINGS}>Profile</Link>, 'profile-settings', <UserOutlined />),
          ...(import.meta.env.VITE_SUBSCRIPTIONS_ENABLED === 'true' ? [getItem(<Link to={Routes.SUBSCRIPTION_SETTINGS}>Subscription</Link>, 'subscription', <DollarOutlined />)] : []),
          getItem(<Link to={Routes.SUPPORT_SETTINGS}>Support</Link>, 'support', <MailOutlined />),
        ]),
      ]}
    />
  )
}

interface AppSiderProps {
  primaryPageKey?: string
}

export const AppSider = ({ primaryPageKey = 'dashboard' }: AppSiderProps) => {
  const [collapsed, setCollapsed] = useState(localStorageGetItem(LocalStorageKeys.SiderCollapse) === 'true')

  return (
    <Sider
      theme="light"
      style={{
        position: 'sticky',
        top: 0,
        height: '100vh',
        zIndex: 1001,
      }}
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => {
        setCollapsed(value)
        localStorageSetItem(LocalStorageKeys.SiderCollapse, JSON.stringify(value))
      }}
    >
      <Link to={Routes.DASHBOARD}>
        <div style={{ textAlign: 'center', padding: '20px 0' }}>
          <img src={logo} alt="company logo" style={{ height: '45px', width: '45px' }} />
        </div>
      </Link>
      {NavigationMenu(primaryPageKey)}
    </Sider>
  )
}
