import { Card as _Card, Col, Row, Space, Typography, theme, Button as _Button } from 'antd'
import CheckSvg from '../../../assets/quickstart_assets/check.svg'
import IphoneScreen from '../../../assets/quickstart_assets/iphone-screen.svg'
import FoxtraxLogo from '../../../assets/quickstart_assets/foxtrax.svg'
import AppleStore from '../../../assets/quickstart_assets/appstore.svg'
import GooglePlay from '../../../assets/quickstart_assets/googleplay.svg'
import styled from 'styled-components'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Routes } from '../../../Routes'
import { useState } from 'react'
import UTMWarningModal from '../Subscription/UTMWarningModal'

const Button = styled(_Button)`
  font-weight: 700;
  height: auto !important;
  padding: 10px 24px !important;
`

const { Text } = Typography

const MainSpace = styled(Space)`
  width: 100%;
  > .ant-space-item {
    display: flex;
    justify-content: center;
    width: 100%;
  }
`
const MinorSpace = styled(Space)<{ screen: number }>`
  width: 100%;
  > .ant-space-item {
    @media (max-width: ${(props) => props.screen.toString()}px) {
      justify-content: center;
    }
    display: flex;
    justify-content: start;
    width: 100%;
  }
`

const Card = styled(_Card)`
  .ant-card-body {
    padding: 24px 24px 0 24px;
  }
`

export const SetupCompleted = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [isModalOpen, setIsModalVisible] = useState(searchParams.get('success') === 'true')

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const {
    token: { colorPrimary, colorTextTertiary, colorBgContainer, screenLG },
  } = theme.useToken()
  return (
    <>
      <UTMWarningModal isOpen={isModalOpen} onClose={handleOk} />
      <MainSpace direction="vertical">
        <img src={CheckSvg} />
        <Text style={{ fontWeight: 700, fontSize: 36 }}>Setup Completed</Text>
        <Text style={{ fontWeight: 600, fontSize: 16, color: colorTextTertiary }}>Your Setup has been successfully completed. You can go to your dashboard now.</Text>
        <Button type="primary" ghost size="large" onClick={() => navigate(Routes.DASHBOARD)}>
          Go to Dashboard
        </Button>
        <Card style={{ width: '100%', background: colorPrimary, borderRadius: 24 }}>
          <Row style={{ flexFlow: 'wrap-reverse' }}>
            <Col xs={24} lg={10} style={{ display: 'flex', alignItems: 'end' }}>
              <img style={{ maxWidth: '100%' }} src={IphoneScreen} />
            </Col>
            <Col xs={24} lg={14} style={{ marginBottom: 24, display: 'flex', alignItems: 'center' }}>
              <MinorSpace screen={screenLG} size={'large'} direction="vertical" style={{ width: '100%' }}>
                <img src={FoxtraxLogo} />
                <Text style={{ fontSize: 24, fontWeight: 600, color: colorBgContainer }}>Get Better Experience Anywhere</Text>
                <Text style={{ fontSize: 16, fontWeight: 400, color: colorBgContainer }}>
                  Download FoxTrax App now and unlock a world of convenience and enhanced functionality, no matter where you are.
                </Text>
                <Row>
                  <Col xs={24} lg={12} style={{ padding: '0 4px' }}>
                    <a href="https://apps.apple.com/us/app/facebook/id284882215?itsct=apps_box_badge&amp;itscg=30200">
                      <img width={'100%'} height={52} alt="Download on the App Store" src={AppleStore} />
                    </a>
                  </Col>
                  <Col xs={24} lg={12} style={{ padding: '0 4px' }}>
                    <a href="https://play.google.com/store/search?q=facebook&c=apps&hl=en&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                      <img width={'100%'} height={52} alt="Get it on Google Play" src={GooglePlay} />
                    </a>
                  </Col>
                </Row>
              </MinorSpace>
            </Col>
          </Row>
        </Card>
      </MainSpace>
    </>
  )
}
