import React, { useEffect, useContext } from 'react'
import { notification } from 'antd'
import { LoadingOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { useViewerQuery, useConnectShopifyToFoxtraxMutation, useCreateShopifyTokenMutation, GetUserStatusDocument, useGetShopifyAuthUrlLazyQuery } from '../../__generated__/graphql'
import { useSearchParams } from 'react-router-dom'
import { JwtContext } from '../../contexts/jwtContext'

interface ShopifyAuthProps {
  attemptLink: boolean
  icon?: React.ReactNode
  title?: string
  description?: string
}

const getStoredValues = () => ({
  storedStoreName: localStorage.getItem('storeName'),
  storedSessionToken: localStorage.getItem('sessionToken'),
  storedHmac: localStorage.getItem('hmac'),
  storedTimestamp: localStorage.getItem('timestamp'),
  storedShopifyToken: localStorage.getItem('shopifyToken'),
  storedHost: localStorage.getItem('host'),
})


const clearStoredValues = (keepStoreName = false) => {
  if (!keepStoreName) {
    localStorage.removeItem('storeName')
  }
  localStorage.removeItem('sessionToken')
  localStorage.removeItem('hmac')
  localStorage.removeItem('timestamp')
  localStorage.removeItem('shopifyToken')
  localStorage.removeItem('host')
}

export const ShopifyAuth: React.FC<ShopifyAuthProps> = ({ attemptLink, icon, title, description }) => {
  const [api, contextHolder] = notification.useNotification()
  const [connectShopifyToFoxtrax, { loading: connectShopifyToFoxtraxLoading }] = useConnectShopifyToFoxtraxMutation({refetchQueries: [GetUserStatusDocument]})
  const [createShopifyToken, { loading: createShopifyTokenLoading }] = useCreateShopifyTokenMutation({refetchQueries: [GetUserStatusDocument]})
  const [getShopifyAuthUrl, { loading: getShopifyAuthUrlLoading }] = useGetShopifyAuthUrlLazyQuery()
  const loading = connectShopifyToFoxtraxLoading || createShopifyTokenLoading || getShopifyAuthUrlLoading
  const [searchParams] = useSearchParams()
  const storeName = searchParams.get('shop')
  const sessionToken = searchParams.get('code')
  const hmac = searchParams.get('hmac')
  const timestamp = searchParams.get('timestamp')
  const host = searchParams.get('host')
  const decodedToken = useContext(JwtContext)
  const { data: viewerData } = useViewerQuery()
  const notificationKey = 'shopifyAuthNotification'

  const showNotification = (type: 'success' | 'error' | 'info', message: React.ReactNode, description: React.ReactNode, icon: React.ReactNode) => {
    api[type]({
      key: notificationKey,
      duration: 0,
      message,
      description,
      icon,
    })
  }

  const handleConnectionError = (currentStoreName: string, organizationName: string) => {
    showNotification(
      'error',
      <>Failed to connect <strong>{currentStoreName}</strong> to <strong>{organizationName}</strong></>,
      <>We failed to connect your Shopify store <strong>{currentStoreName}</strong> to your FoxTrax account <strong>{organizationName}</strong>. Please reach out to support or try again.</>,
      <CloseCircleOutlined style={{ color: '#ff4d4f' }} />
    )
    clearStoredValues()    
  }

  const handleConnectionSuccess = (currentStoreName: string, organizationName: string) => {
    clearStoredValues()
    showNotification(
      'success',
      <>Connected <strong>{currentStoreName}</strong> to <strong>{organizationName}</strong></>,
      <>We successfully connected your Shopify store <strong>{currentStoreName}</strong> to your FoxTrax account <strong>{organizationName}</strong>.</>,
      <CheckCircleOutlined style={{ color: '#52c41a' }} />
    )
  }

  const connectStore = (currentStoreName: string) => {
    if (!decodedToken?.stores[0]) {
      handleConnectionError(currentStoreName, viewerData?.viewer?.organization?.name || '')
      return
    }

    connectShopifyToFoxtrax({
      variables: {
        id: decodedToken.stores[0],
        input: {
          shopifyStoreUrl: currentStoreName,
        },
      },
      onCompleted: (data) => {
        if (data?.connectShopifyToFoxtrax?.__typename === 'UnableToAddShopifyStore') {
          handleConnectionError(currentStoreName, viewerData?.viewer?.organization?.name || '')
        } else {
          handleConnectionSuccess(currentStoreName, viewerData?.viewer?.organization?.name || '')
        }
      },
      onError: (error) => {
        handleConnectionError(currentStoreName, viewerData?.viewer?.organization?.name || '')
      },
    })
  }

  const redirectToAuth = (currentStoreName: string) => {
    showNotification('info', title, description, icon)
    getShopifyAuthUrl({
      variables: {
        shopifyAuthUrlInput: {
          hostname: currentStoreName,
        },
      },
    }).then((data) => {
      window.location.href = data?.data?.shopifyAuthUrl ?? ''
    })
  }

  const createTokenAndConnect = (currentStoreName: string, currentHmac: string, currentTimestamp: string, currentHost: string, currentSessionToken: string | null) => {
    createShopifyToken({
      variables: {
        input: {
          host: currentHost,
          code: currentSessionToken,
          hmac: currentHmac,
          shopifyStoreUrl: currentStoreName,
          timestamp: currentTimestamp,
          foxtraxStoreId: decodedToken?.stores[0] ?? null,
        },
      },
      onCompleted: (data) => {
        if (data?.createShopifyToken?.__typename === 'ShopifyTokenResult' && data?.createShopifyToken?.success && data?.createShopifyToken?.connectedToStore) {
          handleConnectionSuccess(currentStoreName, viewerData?.viewer?.organization?.name || '')
        } else if (data?.createShopifyToken?.__typename === 'ShopifyTokenResult' && data?.createShopifyToken?.success && !data?.createShopifyToken?.connectedToStore) {
          clearStoredValues(true)
          localStorage.setItem('shopifyToken', 'true')
          showNotification('info', title, description, icon)
        } else {
          handleConnectionError(currentStoreName, viewerData?.viewer?.organization?.name || '')
        }
      },
      onError: (error) => {
        handleConnectionError(currentStoreName, viewerData?.viewer?.organization?.name || '')
      },
    })
  }

  useEffect(() => {
    const storedStoreName = localStorage.getItem('storeName')
    const organizationName = viewerData?.viewer?.organization?.name || ''

    if (connectShopifyToFoxtraxLoading) {
      showNotification(
        'info',
        <>Connecting <strong>{storedStoreName}</strong> to <strong>{organizationName}</strong></>,
        <>We are linking your Shopify store <strong>{storedStoreName}</strong> to your FoxTrax account <strong>{organizationName}</strong>, please wait a moment.</>,
        <LoadingOutlined />
      )
    }
  }, [connectShopifyToFoxtraxLoading])

  useEffect(() => {
    const { storedStoreName, storedSessionToken, storedHmac, storedTimestamp, storedShopifyToken, storedHost } = getStoredValues()

    const shopifyStoreUrl = storeName || storedStoreName
    const currentSessionToken = sessionToken || storedSessionToken
    const currentHmac = hmac || storedHmac
    const currentTimestamp = timestamp || storedTimestamp
    const currentHost = host || storedHost

    if (!storeName && !storedStoreName) return

    if (!sessionToken && !storedSessionToken && !storedStoreName) {
      redirectToAuth(shopifyStoreUrl ?? '')
      return
    }

    if (!attemptLink && storedStoreName) {
      showNotification('info', title, description, icon)
    }

    if (storedShopifyToken && viewerData && attemptLink) {
      connectStore(shopifyStoreUrl ?? '')
    }

    if (currentSessionToken && shopifyStoreUrl && currentHmac && currentTimestamp && currentHost && !loading && !storedShopifyToken) {
      createTokenAndConnect(shopifyStoreUrl, currentHmac, currentTimestamp, currentHost, currentSessionToken)
    }

    if (storeName && hmac && !storedStoreName) {
      localStorage.setItem('storeName', storeName)
      if(sessionToken) localStorage.setItem('sessionToken', sessionToken)
      localStorage.setItem('hmac', hmac)
      localStorage.setItem('timestamp', timestamp ?? '')
      localStorage.setItem('host', host ?? '')
      const url = new URL(window.location.href)
      url.search = ''
      window.history.replaceState({}, document.title, url.toString())

      if (attemptLink) {
        showNotification('success', 'Success', 'Store information has been successfully saved.', <CheckCircleOutlined style={{ color: '#52c41a' }} />)
      }
    }
  }, [attemptLink, storeName, sessionToken, viewerData])

  return contextHolder
}

