import { Card, Col, Layout, Row, Space as _Space, Typography, Steps as _Steps, theme } from 'antd'
import styled from 'styled-components'
import { ProfileAction } from '../../components/HeaderActions/Profile'
import { useEffect, useState, useContext } from 'react'
import { Button } from '../../components/StyledComponents'
import { breakpoints } from '../../theme/breakpoints'
import { ConnectAdPlatform } from './ConnectAdPlatform'
import { ResponsiveSiderLayout } from '../../components/ResponsiveSiderLayout'
import { SetupOurPixel } from './SetupOurPixel'
import { useLocation, useNavigate } from 'react-router-dom'
 import { JwtContext } from '../../contexts/jwtContext'
 import { useSubscriptionPlanByStoreIdQuery } from '../../__generated__/graphql'
import { Routes } from '../../Routes'
import { SubscriptionCarosel } from './Subscription/SubscriptionCarosel'
import { SetupCompleted } from './SetupCompleted'

const { Content: _Content } = Layout
const { Text } = Typography

const Space = styled(_Space)`
  .ant-space-item {
    overflow-x: scroll;
    overflow-y: hidden;
  }
  @media (min-width: ${breakpoints.sm}) {
    padding: 0 !important;
  }
`

const MobileHeader = styled.div`
  width: 100%;
  text-align: center;
  @media (min-width: ${breakpoints.sm}) {
    display: none;
  }
  display: block;
`
const ContentCard = styled(Card)`
  > .ant-card-body {
    padding: 8px;

    @media (min-width: ${breakpoints.sm}) and (max-width: ${breakpoints.lg}) {
      padding: 24px;
    }

    @media (min-width: ${breakpoints.lg}) {
      padding: 20px 64px;
    }
  }
`
const ButtonText = styled(Text)`
  font-weight: 700;
  font-size: 14px;
`

const Steps = styled(_Steps)<{ backgroundcolor?: string; textcolor?: string; primarycolor?: string }>`
  .ant-steps-item-tail {
    margin-inline-start: 16px !important;
  }
  .ant-steps-item-icon {
    margin-inline-start: 0 !important;
  }
  .ant-steps-item-content {
    display: none !important;
  }
  .ant-steps-item-title {
    line-height: 24px;
    font-weight: 600;
    font-size: 18px;
  }
  .ant-steps-item: not(.ant-steps-item-wait) {
    .ant-steps-item-icon {
      background-color: ${(props) => props.backgroundcolor} !important;
      .ant-steps-icon {
        color: ${(props) => props.textcolor} !important;
      }
    }
  }
  @media (min-width: ${breakpoints.sm}) {
    .ant-steps-item-tail {
      margin-inline-start: 56px !important;
    }
    .ant-steps-item-icon {
      margin-inline-start: 40px !important;
    }
    .ant-steps-item-content {
      display: block !important;
    }
  }
  .ant-steps-item-finish .ant-steps-item-container .ant-steps-item-icon {
    background: ${(props) => props.primarycolor} !important;
  }
  .ant-steps-item-finish .ant-steps-item-container .ant-steps-item-icon .ant-steps-icon {
    color: ${(props) => props.backgroundcolor} !important;
  }
`

const Content = styled(_Content)`
  display: flex;

  flex-direction: column;
  align-items: center;
  margin: 0px;
`

const ContentWrapper = styled.div`
  min-height: 360px;
  max-width: 1200px;
  width: 100%;
  padding: 40px;
`

const getSteps = () => {
  const baseSteps = [
    { title: 'Connect Ad Platform', path: 'connect-accounts' },
    { title: 'Setup Our Pixel', path: 'setup-our-pixel' },
  ]

  if (import.meta.env.VITE_SUBSCRIPTIONS_ENABLED === 'true') {
    baseSteps.push({ title: 'Choose Your Subscription', path: 'choose-your-subscription' })
  }

  return baseSteps
}

export const QuickStart = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const query = new URLSearchParams(location.search)
   const stepFromUrl = parseInt(query.get('step') || '0', 10)
   const [current, setCurrent] = useState(stepFromUrl)
  const [showBackButton, setShowBackButton] = useState(false)
  const [showNextButton, setShowNextButton] = useState(true)
  const decodedToken = useContext(JwtContext)
  let subscriptionData
  let isSubscribed = false
  if (decodedToken) {
    const result = useSubscriptionPlanByStoreIdQuery({
      variables: {
        storeId: decodedToken.stores[0],
      },
      skip: !decodedToken.stores.length,
    })
    subscriptionData = result.data
  }

   isSubscribed = subscriptionData?.subscriptionPlanByStoreId?.__typename === 'SubscriptionPlan' && subscriptionData.subscriptionPlanByStoreId.subscriptionId !== null

  const getCurrentStep = () => {
    const currentPath = location.pathname.split('/').pop()
    return getSteps().findIndex((step) => step.path === currentPath)
  }

  useEffect(() => {
    if (location.pathname === Routes.QUICK_START) {
      navigate(Routes.CONNECT_ACCOUNTS, { replace: true })
    }
    setCurrent(getCurrentStep())
  }, [location.pathname, navigate])

  const goToStep = (index: number) => {
    navigate(`${Routes.QUICK_START}/${getSteps()[index].path}`)
  }

  const steps = getSteps()
  const stepsPages = [
    <ConnectAdPlatform />,
    <SetupOurPixel />,
    import.meta.env.VITE_SUBSCRIPTIONS_ENABLED === 'true' && (
      isSubscribed && current === 2 ? <SetupCompleted /> : <SubscriptionCarosel
        onSubscriptionSelected={(url) => {
          if (!url) {
            setCurrent(current + 1)
          } else {
            window.open(url, '_blank')
          }
        }}
        successUrl={`${Routes.CHOOSE_YOUR_SUBSCRIPTION}?success=true`}
        failUrl={`${Routes.CHOOSE_YOUR_SUBSCRIPTION}`}
      />
    ),
  ].filter(Boolean)

   useEffect(() => {
     if (current > 0) {
       setShowBackButton(true)
     } else {
       setShowBackButton(false)
     }
     if (current >= steps.length - 1 || (isSubscribed && current === 2)) {
       setShowNextButton(false)
       setShowBackButton(false)
     } else {
       setShowNextButton(true)
     }
   }, [current, isSubscribed])

  useEffect(() => {
    setShowBackButton(current >= 1)
    setShowNextButton(current < steps.length - 1)
  }, [current, steps.length])

  const {
    token: { colorPrimary, colorBgContainer, colorText },
  } = theme.useToken()

  return (
    <ResponsiveSiderLayout title="Quick Start" primaryPageKey="quick-start" actions={<ProfileAction />}>
      <Content>
        <ContentWrapper>
          <ContentCard style={{ width: '100%' }}>
            <Space direction="vertical" style={{ width: '100%' }} size={'large'}>
              <Steps
                textcolor={colorPrimary}
                primarycolor={colorPrimary}
                backgroundcolor={colorBgContainer}
                current={current}
                labelPlacement="vertical"
                items={steps}
                direction="horizontal"
                responsive={false}
              />
              <MobileHeader>
                <Text style={{ fontWeight: 600, fontSize: 18 }}>{steps[current]?.title}</Text>
              </MobileHeader>
              {stepsPages[current]}
              <Row justify={{ xs: showBackButton ? 'space-between' : 'center', md: showBackButton ? 'space-between' : 'end' }}>
                {showBackButton && (
                  <Col span={11} md={8}>
                    <Button
                      style={{
                        border: `solid 1px ${colorText} `,
                        borderRadius: 14,
                      }}
                      block
                      onClick={() => goToStep(current - 1)}
                    >
                      Back
                    </Button>
                  </Col>
                )}
                {showNextButton ? (
                  <Col span={showBackButton ? 11 : 24} md={8}>
                    <Button block type="primary" onClick={() => goToStep(current + 1)}>
                      <ButtonText style={{ color: colorBgContainer }}>Next</ButtonText>
                    </Button>
                  </Col>
                ) : (
                  <Col span={showBackButton ? 11 : 24} md={8}>
                    <Button
                      block
                      type="primary"
                      onClick={() => {
                        navigate(`${Routes.DASHBOARD}?success=true`)
                        console.log('Finish button clicked')
                      }}
                    >
                      <ButtonText style={{ color: colorBgContainer }}>Finish</ButtonText>
                    </Button>
                  </Col>
                )}
              </Row>
            </Space>
          </ContentCard>
        </ContentWrapper>
      </Content>
    </ResponsiveSiderLayout>
  )
}
