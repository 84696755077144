import { Button, Layout, Modal, Space as _Space, Typography } from 'antd'
import styled from 'styled-components'
import { ProfileAction } from '../../components/HeaderActions/Profile'
import { ReloadAction } from '../../components/HeaderActions/Reload'
import { AdPlatformAction } from '../../components/HeaderActions/AdPlatform'
import { AttributionCard } from '../../components/AttributionCard'
import { ResponsiveSiderLayout } from '../../components/ResponsiveSiderLayout'
import connectAccount from '../../assets/dashboard_assets/connect-accont.svg'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Routes } from '../../Routes'
import { useContext, useState } from 'react'
import { useGetUserStatusQuery } from '../../__generated__/graphql'
import { JwtContext } from '../../contexts/jwtContext'
import { DashboardRangePicker } from '../../components/DashboardRangePicker'
import { MetricsContainer } from '../../components/MetricsContainer'
import UTMWarningModal from '../QuickStart/Subscription/UTMWarningModal'
import { ShopifyAuth } from '../../components/ShopifyAuth'
const { Content: _Content } = Layout
const { Text } = Typography
const Content = styled(_Content)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 16px;
`

const ContentWrapper = styled.div`
  min-height: 360px;
  width: 100%;
  max-width: 1265px;
`

const Space = styled(_Space)`
  .ant-space-item {
    width: 100%;
  }
`

export const Dashboard = () => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [isModalOpen, setIsModalVisible] = useState(searchParams.get('success') === 'true')
  const handleOk = () => {
    setIsModalVisible(false)
    navigate('.', { replace: true })
  }
  const decodedToken = useContext(JwtContext)

  const { data: userStatusData, loading: userStatusLoading } = useGetUserStatusQuery({
    variables: { id: decodedToken?.stores[0] ?? '' },
    fetchPolicy: 'network-only',
  });
  
  let isSubscribed = userStatusData?.userStatusByStoreId.hasPaidSubscription;
  if (import.meta.env.VITE_SUBSCRIPTIONS_ENABLED === 'false') {
    isSubscribed = true;
  }
  const isConnected = userStatusData?.userStatusByStoreId.isConnectedToFacebook;
  const isAppInstalled = userStatusData?.userStatusByStoreId.hasInstalledShopifyApp;

  const missingSteps = [];
  if (!isConnected) {
    missingSteps.push("Connect your Facebook account.");
  }
  if (!isAppInstalled) {
    missingSteps.push("Install the Foxtrax Shopify app.");
  }
  if (!isSubscribed) {
    missingSteps.push("Subscribe to Foxtrax payment.");
  }

  const getSetupRoute = () => {
    if (!isConnected) {
      return Routes.CONNECT_ACCOUNTS;
    } else if (!isAppInstalled) {
      return Routes.SETUP_OUR_PIXEL;
    } else if (!isSubscribed) {
      return Routes.CHOOSE_YOUR_SUBSCRIPTION;
    }
    return null;
  };

  const setupRoute = getSetupRoute();

  return (
    <ResponsiveSiderLayout
      title="Dashboard"
      primaryPageKey="dashboard"
      actions={[<ReloadAction key={1} />, <AdPlatformAction key={2} />, <DashboardRangePicker key={3} />, <ProfileAction key={4} />]}
      mobileActions={[<ProfileAction key={4} />]}
    >
      <Content>
        <ShopifyAuth attemptLink={true} />
        <ContentWrapper>
          <MetricsContainer />
          <AttributionCard />
        </ContentWrapper>
      </Content>
      <UTMWarningModal isOpen={isModalOpen} onClose={handleOk} />
      {(!userStatusLoading) && (
        <Modal centered open={missingSteps.length > 0} footer={null} closable={false}>
          <Space direction="vertical" style={{ width: '100%', textAlign: 'center' }} align="center">
            <img src={connectAccount}></img>
            <div style={{ marginTop: -40 }}>
              <Text style={{ fontWeight: 700, fontSize: 24 }}>Complete the following steps to finish setup</Text>
              <ul style={{ textAlign: 'left', marginTop: 20 }}>
                {missingSteps.map((step, index) => (
                  <li key={index} style={{ fontSize: 16 }}>{step}</li>
                ))}
              </ul>
            </div>
            <Button
              onClick={() => setupRoute && navigate(setupRoute)}
              type="primary"
              block
              style={{ fontWeight: 700, fontSize: 16, padding: '12px 24px', height: 60, width: '100%', borderRadius: 14 }}
            >
              Continue Setup
            </Button>
          </Space>
        </Modal>
      )}
    </ResponsiveSiderLayout>
  )
}
