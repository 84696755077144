import { Row as _Row, Col } from "antd";
import styled from "styled-components";
import { LoginForm } from "../../components/LoginForm";
import { breakpoints } from "../../theme/breakpoints";
import { Background } from "../../components/StyledComponents/OnboardingBackground";
import snowFox from "../../assets/snow-fox-4.jpg";
import { ShopifyAuth } from "../../components/ShopifyAuth";
import { InfoCircleOutlined } from "@ant-design/icons";

const Row = styled(_Row)`
  min-height: 100vh;
  height: fit-content;
`;

const Container = styled.div`
  padding: 16px;
  @media (max-width: ${breakpoints.sm}){
    height: 100%;
    margin: auto;
    padding: 0;
  }
`


export const Login = () => {

  return (
    <>
      <ShopifyAuth attemptLink={false} icon={<InfoCircleOutlined />} title="FoxTrax Shopify App" description="To link your Shopify store, you'll need to register or log in." />
      <Row>
        <Col xs={0} sm={0} md={12} lg={12}>
          <Background src={snowFox} scale="stretch">
            <div
              style={{ position: "absolute", bottom: 0, margin: "86px 50px" }}
            >
            </div>
          </Background>
        </Col>
        <Col
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          xs={24} sm={24} md={12} lg={12}
        >
          <Container>
            <LoginForm />
          </Container>
        </Col>
      </Row>
    </>
  );
};
