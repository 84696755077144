import { Button as _Button, Card, Col, Row, Space, Typography, theme } from 'antd'
import styled from 'styled-components'
import { useContext } from 'react'
import { CheckCircleFilled } from '@ant-design/icons'
import { useShopifyInstallUrlQuery, useGetUserStatusQuery } from '../../../__generated__/graphql'
import { JwtContext } from '../../../contexts/jwtContext'

const { Text } = Typography

const TitleText = styled(Text)`
  font-weight: 600;
  font-size: 20px;
`

const Button = styled(_Button)`
  font-weight: 700;
  background-color: #1877f2;
  color: white;
  overflow: clip;
  white-space: wrap;
  height: auto !important;
  padding: 10px 24px !important;

  &:not([disabled]):hover {
    color: white !important;
  }
`

export const SetupOurPixel = () => {
  const decodedToken = useContext(JwtContext)
  const { data, loading:loadingShopifyUrl } = useShopifyInstallUrlQuery()
  const { data: userStatusData, loading: userStatusLoading } = useGetUserStatusQuery({
    variables: { id: decodedToken?.stores[0] ?? '' },
    fetchPolicy: 'network-only',
  })
  const loading = () => userStatusLoading || loadingShopifyUrl

  const isConnected = userStatusData?.userStatusByStoreId.hasInstalledShopifyApp

  const {
    token: { colorBgLayout, colorSuccess, colorTextTertiary },
  } = theme.useToken()

  return (
    <>
      <Space style={{ width: '100%' }} size={'large'} direction="vertical">
        <Card style={{ backgroundColor: colorBgLayout, borderRadius: 16 }}>
          <Row gutter={[16, 16]} align="middle" justify="space-between">
            <Col span={24} md={12}>
              <TitleText style={{ textAlign: 'center' }}>Install Foxtrax Shopify App</TitleText>
            </Col>
            <Col span={24} md={12} lg={10} style={{ textAlign: 'center' }}>
              <Button loading={loading()} disabled={isConnected} onClick={() => !isConnected && data?.shopifyInstallUrl && window.open(data?.shopifyInstallUrl, '_blank')} type="primary" size="large" block>
                {isConnected ? (
                  <Space direction="horizontal" style={{ marginBottom: 4 }}>
                    <CheckCircleFilled style={{ fontSize: '18px', color: colorSuccess }} />
                    <Text style={{ color: colorTextTertiary }}>Connected</Text>
                  </Space>
                ) : (
                  'Connect my Shopify store'
                )}
              </Button>
            </Col>
          </Row>
        </Card>
      </Space>
    </>
  )
}

export default SetupOurPixel
