import { Card as Card, Col, Row, Space, Typography, theme, Rate, Avatar, Badge } from 'antd'
import { Button } from '../../../components/StyledComponents'
import { useContext, useEffect, useRef, useState } from 'react'
import Flicking from '@egjs/react-flicking'
import '@egjs/react-flicking/dist/flicking.css'
import { SubscriptionCard } from './SubscriptionCard'
import styled from 'styled-components'
import { colorPrimary, colorTextTertiary } from '../../../theme/colors'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { useSubscriptionLinkMutation } from '../../../__generated__/graphql'
import { JwtContext } from '../../../contexts/jwtContext'

const Fade = styled.div`
  display: flex;
  position: absolute;
  z-index: 10;
  top: 0;
  bottom: 0;
  margin: auto 0;
  height: 100%;
  width: 30%;
`

const { Text } = Typography
const PlansWrapper = styled(Card)<{ background?: string; backgroundmd?: string }>`
  background: ${(props) => props.backgroundmd} !important;
  border: none;
  & .ant-card-body {
    padding: 0px !important;
  }
`

export const SubscriptionCarosel = ({ onSubscriptionSelected, successUrl, failUrl }: { onSubscriptionSelected: (link: string | undefined) => void; successUrl: string; failUrl: string }) => {
  const plans = [
    // {
    //   name: 'Free',
    //   description: 'Visualize all your data on one central dashboard & access all your data on the go with our mobile app.',
    //   price: '0',
    //   oldPrice: '18',
    //   features: ['Mobile App Dashboard', 'Web Dashboard'],
    // },
    {
      name: 'Standard',
      description: 'Accurately track your ads, make informed decisions, and scale your ROAS.',
      price: '50',
      oldPrice: '100',
      features: ['Pixel Attribution', 'Support', 'Mobile App Dashboard', 'Web Dashboard'],
      recommended: true,
    },
  ]

  const yearPlans = [
    // {
    //   name: 'Free',
    //   description: 'Visualize all your data on one central dashboard & access all your data on the go with our mobile app.',
    //   price: '0',
    //   oldPrice: '15',
    //   features: ['Mobile App Dashboard', 'Web Dashboard'],
    // },
    {
      name: 'Standard',
      description: 'Accurately track your ads, make informed decisions, and scale your ROAS.',
      price: '480',
      oldPrice: '600',
      features: ['Pixel Attribution', 'Support', 'Mobile App Dashboard', 'Web Dashboard'],
      recommended: true,
    },
  ]

  const [createCheckoutSession] = useSubscriptionLinkMutation()
  const [yearlySubscription, setYearlySubscription] = useState(true)
  const [subscribeUrl, setSubscribeUrl] = useState('')
  const decodedToken = useContext(JwtContext)
  const storeId = decodedToken?.stores[0]

  useEffect(() => {
    async function fetchCheckoutSession() {
      if (!storeId) return

      try {
        const { data } = await createCheckoutSession({
          variables: {
            id: storeId,
            input: {
              isYearly: yearlySubscription,
              successUrl: successUrl,
              cancelUrl: failUrl,
            },
          },
        })

        if (data && data?.createCheckoutSessionByStoreId?.__typename === 'CheckoutSession') {
          setSubscribeUrl(data.createCheckoutSessionByStoreId.sessionUrl)
        }
      } catch (error) {
        // Handle error (e.g., display a message to the user)
      }
    }

    fetchCheckoutSession()
  }, [storeId, createCheckoutSession, yearlySubscription])

  // const [grossRevenue, setGrossRevenue] = useState()
  const flickingRefPlans = useRef<Flicking | null>(null)
  const flickingRefReviews = useRef<Flicking | null>(null)

  const maxReviewCount = 3
  const minReviewCardWidth = 300
  const [reviewPanels, setReviewPanels] = useState(maxReviewCount)

  useEffect(() => {
    const flickerLarge = document.querySelector('#flicker-large')

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.target.id === 'flicker-large') {
          const width = entry.contentRect.width
          if (Math.floor(width / minReviewCardWidth) > maxReviewCount) setReviewPanels(maxReviewCount)
          else setReviewPanels(Math.ceil(width / minReviewCardWidth))
        }
      }
    })

    if (flickerLarge) {
      resizeObserver.observe(flickerLarge)
    }
    return () => {
      resizeObserver.disconnect()
    }
  }, [])
  const {
    token: { colorBorder, colorBorderSecondary, colorTextSecondary },
  } = theme.useToken()
  return (
    <Space direction="vertical" style={{ width: '100%', padding: '0px' }}>
      <PlansWrapper>
        <Space style={{ width: '100%', justifyContent: 'center', textAlign: 'center' }} size={'large'} direction="vertical">
          <Text style={{ fontSize: 28, fontWeight: 600 }}>Special Launch Offer 🔥</Text>
          <Row justify={'center'} style={{ overflow: 'visible' }}>
            <Col sm={0} xs={0} md={24} style={{ paddingTop: '5px' }}>
              <div style={{ background: `${colorBorderSecondary}`, border: `solid 1px ${colorBorder}`, borderRadius: 14, padding: 4, width: 'fit-content', margin: 'auto' }}>
                <Button
                  style={{ width: 240, height: 46, color: yearlySubscription ? colorTextTertiary : `` }}
                  type={yearlySubscription ? 'text' : 'primary'}
                  onClick={() => {
                    setYearlySubscription(false)
                  }}
                >
                  Monthly
                </Button>
                <Badge color="#FBBC05" style={{ color: colorTextSecondary, right: 30, boxShadow: 'none' }} count={'20% Off'}>
                  <Button
                    style={{ width: 240, height: 46, color: yearlySubscription ? `` : colorTextTertiary }}
                    type={yearlySubscription ? 'primary' : 'text'}
                    onClick={() => {
                      setYearlySubscription(true)
                    }}
                  >
                    Yearly
                  </Button>
                </Badge>
              </div>
            </Col>
            <Col sm={24} xs={24} md={0}>
              <div
                style={{
                  color: yearlySubscription ? colorTextTertiary : ``,
                  background: `${colorBorderSecondary}`,
                  border: `solid 1px ${colorBorder}`,
                  borderRadius: 14,
                  padding: 4,
                  margin: 'auto',
                  width: 'fit-content',
                }}
              >
                <Button
                  style={{ width: 108, height: 46 }}
                  type={yearlySubscription ? 'text' : 'primary'}
                  onClick={() => {
                    setYearlySubscription(false)
                  }}
                >
                  Monthly
                </Button>
                <Button
                  style={{ width: 108, height: 46, color: yearlySubscription ? `` : colorTextTertiary }}
                  type={yearlySubscription ? 'primary' : 'text'}
                  onClick={() => {
                    setYearlySubscription(true)
                  }}
                >
                  Yearly
                </Button>
              </div>
            </Col>
          </Row>

          <Row justify={'center'} align={'middle'}>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'center', gap: '30px', padding: '0px' }}>
              {(yearlySubscription ? yearPlans : plans).map(({ name, price, oldPrice, description, features, recommended }, index) => {
                return (
                  <Col span={24} md={12} key={index} style={{display: 'flex', justifyContent: "center"}}>
                    <SubscriptionCard
                      link={name != 'Free' ? subscribeUrl : undefined}
                      name={name}
                      description={description}
                      price={price.toString()}
                      oldPrice={oldPrice.toString()}
                      features={features}
                      onChooseSubscription={onSubscriptionSelected}
                      recommended={recommended}
                    />
                  </Col>
                )
              })}
            </div>
            {/* <Col span={48} md={0}>
              <Flicking ref={flickingRefPlans} style={{ padding: ' 8px 0', margin: 'auto', minWidth: '200px' }} defaultIndex={1} moveType="snap" align={'center'}>
                {(yearlySubscription ? yearPlans : plans).map(({ name, price, oldPrice, description, features, recommended }, index) => {
                  console.log('build', name)
                  return (
                    <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }} key={index}>
                      <SubscriptionCard
                        name={name}
                        link={name != 'Free' ? subscribeUrl : undefined}
                        description={description}
                        price={price.toString()}
                        oldPrice={oldPrice.toString()}
                        features={features}
                        recommended={recommended}
                        onChooseSubscription={onSubscriptionSelected}
                        yearlySwtich={setYearlySubscription}
                      />
                    </div>
                  )
                })}
              </Flicking>
            </Col> */}
          </Row>
        </Space>
      </PlansWrapper>
      <Row style={{ position: 'relative' }}>
        {reviewPanels > 2 ? (
          <>
            <Fade style={{ left: 0, padding: '25px', justifyContent: 'start', background: 'linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))' }}>
              <Button
                style={{ margin: 'auto 0', borderRadius: 100, height: 40, width: 40 }}
                onClick={() => {
                  flickingRefReviews.current?.prev()
                }}
                icon={<LeftOutlined style={{ color: colorPrimary }} />}
              ></Button>
            </Fade>
            <Fade style={{ right: 0, padding: '25px', justifyContent: 'end', background: 'linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))' }}>
              <Button
                style={{ margin: 'auto 0', borderRadius: 100, height: 40, width: 40 }}
                onClick={() => {
                  flickingRefReviews.current?.next()
                }}
                icon={<RightOutlined style={{ color: colorPrimary }} />}
              ></Button>
            </Fade>
          </>
        ) : (
          <></>
        )}
        <Flicking
          ref={flickingRefReviews}
          id="flicker-large"
          style={{ width: '100%', padding: '16px', margin: 'auto' }}
          panelsPerView={reviewPanels}
          align={'prev'}
          defaultIndex={reviews.length / 2}
          moveType="snap"
          bound={true}
        >
          <div></div>
          {reviews.map(({ name, avatar, company, rating, review }, index) => {
            return (
              <div style={{ width: 320, marginLeft: 16 }} key={index}>
                <Card>
                  <Space direction="vertical">
                    <Rate disabled={true} defaultValue={rating} />
                    <Text style={{ fontSize: 16, fontWeight: 400, color: colorTextSecondary }}>{review}</Text>
                    <Space direction="horizontal" style={{ marginTop: 8 }}>
                      <Avatar size={'large'} src={avatar} />
                      <Space direction="vertical" style={{ rowGap: 0, columnGap: 0 }}>
                        <Text style={{ fontSize: 14, fontWeight: 400 }}>{name}</Text> <Text style={{ fontSize: 12, fontWeight: 400, color: colorTextSecondary }}>{company}</Text>
                      </Space>
                    </Space>
                  </Space>
                </Card>
              </div>
            )
          })}
          <div></div>
        </Flicking>
      </Row>
    </Space>
  )
}

const reviews = [
  {
    rating: 5,
    name: 'Leslie Alexander',
    company: 'Foxtrax.io',
    avatar: 'https://xsgames.co/randomusers/assets/avatars/pixel/14.jpg',
    review: 'Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur ma',
  },
  {
    rating: 5,
    name: 'Leslie Alexander',
    company: 'Foxtrax.io',
    avatar: 'https://xsgames.co/randomusers/assets/avatars/pixel/15.jpg',
    review: 'Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur ma',
  },
  {
    rating: 4,
    name: 'Leslie Alexander',
    company: 'Foxtrax.io',
    avatar: 'https://xsgames.co/randomusers/assets/avatars/pixel/10.jpg',
    review: 'Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur ma',
  },
  {
    rating: 3,
    name: 'Leslie Alexander',
    company: 'Foxtrax.io',
    avatar: 'https://xsgames.co/randomusers/assets/avatars/pixel/5.jpg',
    review: 'Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur ma',
  },
]
